import styled from "styled-components/macro";

export const Wrapper = styled.div`
  padding: 0.5em 0;
  text-align: left;

  label {
    width: 8em;
    display: inline-block;
  }
`;

export const Label = styled.label`
  &:after {
    content: ": ";
  }
  margin-right: 1em;
`;
