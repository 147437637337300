import React from "react";
import { Form, Field } from "react-final-form";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import LabelledField from "domain/form/LabelledField";
import Buttons from "domain/form/Buttons";
import Button from "domain/form/Button";
import Fieldset from "domain/form/Fieldset";
import LoginOption from "domain/form/LoginOption";
import { makeRequest } from "domain/auth";

const loginFailedMessage = "Login failed, please try again";

const getDataForServer = ({ googleData, facebookData }) => {
  if (googleData) {
    return {
      token: googleData.tokenObj.id_token,
      provider: "google"
    };
  }

  if (facebookData) {
    return {
      token: facebookData.accessToken,
      provider: "facebook"
    };
  }

  throw new Error("No suitable data to choose from");
};

const onSubmit = (setAuthenticatedUser, history) => async ({
  email,
  password,
  googleData,
  facebookData,
  rememberme
}) => {
  const loginParams = (() => {
    if (email) {
      return {
        endpoint: "login",
        data: { email, password, rememberme }
      };
    }
    if (googleData || facebookData) {
      if (googleData && !googleData.tokenObj) {
        throw new Error("No tokenObj object in Google Data");
      }
      return {
        endpoint: "login-syndicated",
        data: getDataForServer({ googleData, facebookData })
      };
    }
  })();

  const responseData = await makeRequest(loginParams);

  if (!(responseData && responseData.success)) {
    if (
      responseData.message &&
      responseData.message === "Email not confirmed"
    ) {
      history.push("/confirm-email-message");
    } else {
      window.alert(loginFailedMessage);
    }

    return;
  }

  if (!responseData.tokenData) {
    throw new Error("Auth token data not found in login response");
  }
  if (!responseData.user) {
    throw new Error("Auth user not found in login response");
  }

  setAuthenticatedUser(responseData);

  history.push("/dashboard");
};

const LoginForm = ({ history, setAuthenticatedUser }) => {
  const onSubmitCallback = onSubmit(setAuthenticatedUser, history);
  return (
    <Form
      onSubmit={onSubmitCallback}
      initialValues={{ email: "", password: "" }}
      render={({ handleSubmit, form, submitting, pristine, values }) => (
        <form onSubmit={handleSubmit}>
          <LoginOption>
            <GoogleLogin
              clientId={process.env.REACT_APP_GOOGLE_SIGNIN_CLIENT_ID}
              buttonText="Login with Google"
              onSuccess={response =>
                onSubmitCallback({
                  googleData: response,
                  ...values
                })
              }
              onFailure={() => window.alert(loginFailedMessage)}
            />
          </LoginOption>

          <LoginOption>
            <FacebookLogin
              appId={process.env.REACT_APP_FACEBOOK_SIGNIN_APP_ID}
              disableMobileRedirect={true}
              render={renderProps => (
                <Button
                  type="button"
                  className="facebookButton"
                  onClick={renderProps.onClick}
                >
                  Login with Facebook
                </Button>
              )}
              callback={response => {
                // TODO:WV:20190329:Handle failed login well / check this is working
                if (!response.accessToken) {
                  window.alert(loginFailedMessage);
                  return;
                }
                onSubmitCallback({
                  facebookData: response,
                  ...values
                });
              }}
            />
          </LoginOption>

          <Fieldset>
            <LabelledField
              label="Email"
              fieldId="email"
              field={
                <Field
                  name="email"
                  component="input"
                  type="text"
                  placeholder="Email"
                  id="email"
                />
              }
            />
            <LabelledField
              label="Password"
              fieldId="password"
              field={
                <Field
                  name="password"
                  component="input"
                  type="password"
                  placeholder="Password"
                  id="password"
                />
              }
            />
            <LabelledField
              label="Remember me"
              fieldId="rememberme"
              field={
                <Field
                  name="rememberme"
                  component="input"
                  type="checkbox"
                  id="rememberme"
                />
              }
            />
            <Buttons>
              <Button type="submit" disabled={submitting || pristine}>
                Submit
              </Button>
              <Button
                type="button"
                disabled={submitting || pristine}
                onClick={form.reset}
              >
                Reset
              </Button>
            </Buttons>
          </Fieldset>
        </form>
      )}
    />
  );
};

export default LoginForm;
