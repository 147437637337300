import React from "react";
import AuthPage from "domain/pages/AuthPage";
import RegistrationForm from "./RegistrationForm";
import P from "domain/elements/text/P";
import { Link } from "react-router-dom";

const RegistrationPage = ({ history, setAuthenticatedUser }) => (
  <AuthPage>
    <RegistrationForm
      history={history}
      setAuthenticatedUser={setAuthenticatedUser}
    />
    <P>
      <Link to="/login">Login</Link>
    </P>
  </AuthPage>
);

export default RegistrationPage;
