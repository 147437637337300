import React from "react";
import { loader as loadGraphQL } from "graphql.macro";
import { Query } from "react-apollo";

const QUERY_DASHBOARD = loadGraphQL("./dashboard.graphql");

const logOut = (clearSession, history) => async () => {
  await clearSession();
  history.push("/login");
};

const DashboardPage = ({ history, getAuthenticatedUser, clearSession }) => {
  const authenticatedUser = getAuthenticatedUser();

  return (
    <Query query={QUERY_DASHBOARD} variables={{ userid: authenticatedUser.id }}>
      {({ loading, data: { user }, error }) => (
        <React.Fragment>
          <p>Welcome, {authenticatedUser.firstName}, you are logged in!</p>
          <p>Your user role is '{authenticatedUser.role}'.</p>

          {loading ? (
            <p>Loading class data...</p>
          ) : user && user.bookedClasses && user.bookedClasses.length ? (
            <React.Fragment>
              <p>You have the following classes stored in the system:</p>
              <ul>
                {user.bookedClasses.map((bookedclass, i) => (
                  <li key={`bookedclasses-${i}`}>
                    {bookedclass.title}, {bookedclass.location}
                  </li>
                ))}
              </ul>
            </React.Fragment>
          ) : (
            <p>You have no classes stored in the system at the moment</p>
          )}

          <p>
            <button onClick={logOut(clearSession, history)}>Log out</button>
          </p>
        </React.Fragment>
      )}
    </Query>
  );
};

export default DashboardPage;
