const tokenDataStorageKey = "authTokenData";
const userStorageKey = "authUser";

const authEndpointURL = process.env["REACT_APP_AUTH_ENDPOINT_URL"];

const tryDecodeJSON = str => {
  try {
    return str ? JSON.parse(str) : null;
  } catch (e) {
    return null;
  }
};

const storage = {
  set: (key, obj) => window.sessionStorage.setItem(key, JSON.stringify(obj)),
  get: key => tryDecodeJSON(window.sessionStorage.getItem(key))
};

export const getCurrentAuthTokenData = () => {
  const tokenData = storage.get(tokenDataStorageKey);

  const { exp } = tokenData || {};
  const secondsUntilExpiry = exp
    ? exp - Math.floor(new Date().getTime() / 1000)
    : null;
  const user = storage.get(userStorageKey);

  return {
    secondsUntilExpiry,
    user,
    haveNonExpiredSession:
      user && secondsUntilExpiry && secondsUntilExpiry > 0 ? true : false
  };
};

export const getAuthenticatedUser = () => {
  const { user, haveNonExpiredSession } = getCurrentAuthTokenData();

  return haveNonExpiredSession ? user : null;
};

export const setAuthenticatedUser = ({ tokenData, user }) => {
  storage.set(tokenDataStorageKey, tokenData);
  storage.set(userStorageKey, user);
};

export const clearSession = async () => {
  await makeRequest({ endpoint: "logout" });
  clearSessionLocally();
};

export const clearSessionLocally = () => {
  window.sessionStorage.removeItem(tokenDataStorageKey);
  window.sessionStorage.removeItem(userStorageKey);
};

const postData = async ({ url, data }) => {
  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: {
      "Content-Type": "application/json"
    },
    redirect: "follow",
    referrer: "no-referrer",
    body: JSON.stringify(data)
  });

  try {
    const json = await response.json();
    return json;
  } catch (e) {
    return {};
  }
};

export const makeRequest = async ({ endpoint, data = {} }) => {
  const responseData = await postData({
    url: `${authEndpointURL}/${endpoint}`,
    data
  });
  return responseData;
};
