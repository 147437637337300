import React from "react";
import { Form, Field } from "react-final-form";
import LabelledField from "domain/form/LabelledField";
import FieldError from "domain/form/FieldError";
import Buttons from "domain/form/Buttons";
import Button from "domain/form/Button";
import Fieldset from "domain/form/Fieldset";
import { makeRequest } from "domain/auth";
import {
  required,
  minLength,
  maxLength,
  exactValue,
  composeValidators
} from "domain/form/validators";

const onSubmit = ({ history }) => async ({
  newpassword,
  exp,
  userid,
  code
}) => {
  const responseData = await makeRequest({
    endpoint: "resetpasswordstep2",
    data: { newpassword, exp, userid, code }
  });

  if (!(responseData && responseData.success)) {
    window.alert("Request failed, please try again");
    return;
  }

  window.alert("Password changed!");
  history.push("/dashboard");
};

const ForgottenPasswordStep2Form = ({ history, qs: { exp, userid, code } }) => (
  <Form
    onSubmit={onSubmit({ history })}
    initialValues={{ exp, userid, code }}
    render={({ handleSubmit, form, submitting, pristine, values }) => (
      <form onSubmit={handleSubmit}>
        <Fieldset>
          <Field name="exp" component="input" type="hidden" />
          <Field name="userid" component="input" type="hidden" />
          <Field name="code" component="input" type="hidden" />
          <LabelledField
            label="New password"
            fieldId="newpassword"
            field={
              <Field
                name="newpassword"
                validate={composeValidators(
                  required,
                  minLength(9),
                  maxLength(20)
                )}
              >
                {({ input, meta }) => (
                  <React.Fragment>
                    <input
                      {...input}
                      type="password"
                      placeholder="Password"
                      id="password"
                    />
                    <FieldError meta={meta} />
                  </React.Fragment>
                )}
              </Field>
            }
          />
          <LabelledField
            label="Confirm new password"
            fieldId="confirmnewpassword"
            field={
              <Field
                name="confirmnewpassword"
                validate={exactValue(
                  values.newpassword,
                  "Passwords do not match"
                )}
              >
                {({ input, meta }) => (
                  <React.Fragment>
                    <input
                      {...input}
                      type="password"
                      placeholder="Confirm new password"
                      id="confirmnewpassword"
                    />
                    <FieldError meta={meta} />
                  </React.Fragment>
                )}
              </Field>
            }
          />
          <Buttons>
            <Button type="submit" disabled={submitting || pristine}>
              Submit
            </Button>
            <Button
              type="button"
              disabled={submitting || pristine}
              onClick={form.reset}
            >
              Reset
            </Button>
          </Buttons>
        </Fieldset>
      </form>
    )}
  />
);

export default ForgottenPasswordStep2Form;
