import React from "react";
import AuthPage from "domain/pages/AuthPage";
import ForgottenPasswordForm from "./ForgottenPasswordForm";
import P from "domain/elements/text/P";
import { Link } from "react-router-dom";

const ForgottenPasswordPage = ({ history }) => (
  <AuthPage>
    <ForgottenPasswordForm />
    <P>
      <Link to="/login">Log in</Link>
    </P>
  </AuthPage>
);

export default ForgottenPasswordPage;
