import React from "react";
import { Form, Field } from "react-final-form";
import LabelledField from "domain/form/LabelledField";
import Buttons from "domain/form/Buttons";
import Button from "domain/form/Button";
import Fieldset from "domain/form/Fieldset";
import { makeRequest } from "domain/auth";

const onSubmit = () => async ({ email }) => {
  const responseData = await makeRequest({
    endpoint: "resetpasswordstep1",
    data: { email }
  });

  if (!(responseData && responseData.success)) {
    window.alert("Request failed, please try again");
    return;
  }

  window.alert(
    "Thank you.  If you are registered in the system with that email address, you will be sent an email with a link to reset your password."
  );
};

const ForgottenPasswordForm = () => (
  <Form
    onSubmit={onSubmit()}
    initialValues={{ email: "" }}
    render={({ handleSubmit, form, submitting, pristine, values }) => (
      <form onSubmit={handleSubmit}>
        <Fieldset>
          <LabelledField
            label="Email"
            fieldId="email"
            field={
              <Field
                name="email"
                component="input"
                type="text"
                placeholder="Email"
                id="email"
              />
            }
          />
          <Buttons>
            <Button type="submit" disabled={submitting || pristine}>
              Submit
            </Button>
            <Button
              type="button"
              disabled={submitting || pristine}
              onClick={form.reset}
            >
              Reset
            </Button>
          </Buttons>
        </Fieldset>
      </form>
    )}
  />
);

export default ForgottenPasswordForm;
