import styled from "styled-components/macro";

export const Wrapper = styled.div`
  padding: 1em 0;
  text-align: left;

  button {
    margin-right: 1em;
  }
`;
