import React from "react";

import { Wrapper, Label } from "./LabelledField.styles";

const LabelledField = ({ label, field, fieldId }) => (
  <Wrapper>
    <Label {...(fieldId ? { htmlFor: fieldId } : {})}>{label}</Label>
    {field}
  </Wrapper>
);

export default LabelledField;
