import React from "react";
import AuthPage from "domain/pages/AuthPage";
import ForgottenPasswordStep2Form from "./ForgottenPasswordStep2Form";
import P from "domain/elements/text/P";
import { Link } from "react-router-dom";
import qs from "query-string";

const ForgottenPasswordStep2Page = ({ history, location }) => (
  <AuthPage>
    <ForgottenPasswordStep2Form
      history={history}
      qs={qs.parse(location.search)}
    />
    <P>
      <Link to="/login">Log in</Link>
    </P>
  </AuthPage>
);

export default ForgottenPasswordStep2Page;
