import React from "react";
import { Container, ContainerInner } from "./AuthPage.styles";
import logo from "./logo-dark.png";
import Img from "domain/elements/Img";
import P from "domain/elements/text/P";
import privacyPolicyPDF from "files/privacy-policy.pdf";

const AuthPage = ({ children }) => (
  <Container>
    <ContainerInner>
      <Img src={logo} alt="Roots2Grow Logo" />
      {children}
      <P>
        <a href={privacyPolicyPDF}>Privacy policy</a>
      </P>
    </ContainerInner>
  </Container>
);

export default AuthPage;
