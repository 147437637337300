import React from "react";
import { ErrorMessage } from "domain/form/formstyles";

const FieldError = ({ meta }) => {
  if (!meta.touched) {
    return null;
  }

  const errMsg = meta.error || meta.submitError;

  if (!errMsg) {
    return null;
  }

  return <ErrorMessage>{errMsg}</ErrorMessage>;
};

export default FieldError;
