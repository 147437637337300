export const required = value => (value ? undefined : "Required");
export const email = value =>
  /\S+@\S+\.\S+/.test(value) ? undefined : "Must be an email address";
export const minLength = minLength => value =>
  value.length >= minLength
    ? undefined
    : `Too short (min: ${minLength} characters)`;
export const maxLength = maxLength => value =>
  value.length <= maxLength
    ? undefined
    : `Too long (max: ${maxLength} characters)`;

export const exactValue = (targetValue, errorMessage) => value =>
  value === targetValue ? undefined : errorMessage;

export const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined);
