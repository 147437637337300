import React from "react";
import {
  makeRequest,
  getCurrentAuthTokenData,
  getAuthenticatedUser,
  setAuthenticatedUser,
  clearSession,
  clearSessionLocally
} from "domain/auth";

const refreshCheckIntervalInSeconds = 60;
const refreshThresholdInSeconds = 300;

const refreshAccessTokenIfNecessary = async ({ apolloClient }) => {
  const { user, secondsUntilExpiry } = getCurrentAuthTokenData();

  const shouldRefresh =
    !user || (user && secondsUntilExpiry < refreshThresholdInSeconds);
  if (!shouldRefresh) {
    return;
  }

  await refreshAccessToken({ apolloClient });
};

const refreshAccessToken = async ({ apolloClient }) => {
  const responseData = await makeRequest({ endpoint: "refreshAccessToken" });
  const haveValidResponse =
    responseData && responseData.tokenData && responseData.user;
  if (!haveValidResponse) {
    clearSessionLocally();
    return;
  }

  const { tokenData, user } = responseData;

  if (tokenData) {
    setAuthenticatedUser({ tokenData, user });
  }
};

class AuthenticatedUser extends React.Component {
  state = {
    loading: true
  };

  componentDidMount() {
    const { apolloClient } = this.props;

    // Although 'loading' is initialised to 'true', set it
    // to 'true' here, as well, for clarity and robustness
    this.setState({ loading: true }, async () => {
      await refreshAccessToken({ apolloClient });
      this.setState({ loading: false });
      window.setInterval(
        () => refreshAccessTokenIfNecessary({ apolloClient }),
        refreshCheckIntervalInSeconds * 1000
      );
    });
  }

  render() {
    const { children } = this.props;
    const { loading } = this.state;
    return children({
      loading,
      getAuthenticatedUser,
      setAuthenticatedUser,
      clearSession
    });
  }
}

export default AuthenticatedUser;
