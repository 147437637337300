import React from "react";
import AuthPage from "domain/pages/AuthPage";
import P from "domain/elements/text/P";
import { Link } from "react-router-dom";

const ConfirmEmailMessagePage = () => (
  <AuthPage>
    <P>It looks like you still need to confirm confirmed your email address.</P>
    <P>
      Please check your email for a confirmation link. If you can't find the
      email, please contact us.
    </P>
    <P>
      <Link to="/login">Log in</Link>
    </P>
  </AuthPage>
);

export default ConfirmEmailMessagePage;
