import React from "react";
import AuthPage from "domain/pages/AuthPage";
import P from "domain/elements/text/P";
import { Link } from "react-router-dom";
import qs from "query-string";
import { makeRequest } from "domain/auth";

class ConfirmEmailPage extends React.Component {
  state = {
    failed: false
  };

  async componentDidMount() {
    const { history, location, setAuthenticatedUser } = this.props;
    const { exp, userid, code } = qs.parse(location.search);

    const responseData = await makeRequest({
      endpoint: "confirmemail",
      data: { exp, userid, code }
    });

    if (!(responseData && responseData.success)) {
      this.setState({ failed: true });
      return;
    }

    if (!responseData.tokenData) {
      throw new Error("Auth token data not found in register response");
    }
    if (!responseData.user) {
      throw new Error("Auth user not found in register response");
    }
    setAuthenticatedUser(responseData);
    history.push("/dashboard");
  }

  render() {
    const { failed } = this.state;

    return (
      <AuthPage>
        {failed && (
          <P>
            Seems like you have followed an incorrect link. Please check and try
            again.
          </P>
        )}
        <P>
          <Link to="/login">Log in</Link>
        </P>
      </AuthPage>
    );
  }
}

export default ConfirmEmailPage;
