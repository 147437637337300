import styled from "styled-components/macro";

export const Container = styled.div`
  text-align: center;
  background-color: white;
  color: #4a300d;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ContainerInner = styled.div`
  width: 20em;

  form {
    text-align: left;
  }

  img {
    width: 100%;
  }
`;
