import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import { compose } from "react-apollo";

import withApollo from "domain/apollo/withApollo";
import LoginPage from "domain/pages/LoginPage";
import ForgottenPasswordPage from "domain/pages/ForgottenPasswordPage";
import ForgottenPasswordStep2Page from "domain/pages/ForgottenPasswordStep2Page";
import ConfirmEmailPage from "domain/pages/ConfirmEmailPage";
import ConfirmEmailMessagePage from "domain/pages/ConfirmEmailMessagePage";
import RegistrationPage from "domain/pages/RegistrationPage";
import DashboardPage from "domain/pages/DashboardPage";
import AuthenticatedUser from "domain/auth/AuthenticatedUser";

const App = props => {
  return (
    <AuthenticatedUser>
      {auth => {
        const { loading, getAuthenticatedUser } = auth;

        if (loading) {
          return null;
        }

        return (
          <Router>
            <Switch>
              <Route
                path="/login"
                render={props => {
                  return getAuthenticatedUser() ? (
                    <Redirect to="/dashboard" />
                  ) : (
                    <LoginPage {...props} {...auth} />
                  );
                }}
              />
              <Route
                path="/forgotten-password"
                render={props => {
                  return getAuthenticatedUser() ? (
                    <Redirect to="/dashboard" />
                  ) : (
                    <ForgottenPasswordPage {...props} {...auth} />
                  );
                }}
              />
              <Route
                path="/forgotten-password-step-2"
                render={props => {
                  return getAuthenticatedUser() ? (
                    <Redirect to="/dashboard" />
                  ) : (
                    <ForgottenPasswordStep2Page {...props} {...auth} />
                  );
                }}
              />
              <Route
                path="/register"
                render={props => {
                  return getAuthenticatedUser() ? (
                    <Redirect to="/dashboard" />
                  ) : (
                    <RegistrationPage {...props} {...auth} />
                  );
                }}
              />
              <Route
                path="/confirm-email-message"
                render={props => {
                  return getAuthenticatedUser() ? (
                    <Redirect to="/dashboard" />
                  ) : (
                    <ConfirmEmailMessagePage {...props} {...auth} />
                  );
                }}
              />
              <Route
                path="/confirm-email"
                render={props => {
                  return getAuthenticatedUser() ? (
                    <Redirect to="/dashboard" />
                  ) : (
                    <ConfirmEmailPage {...props} {...auth} />
                  );
                }}
              />
              <Route
                path="/dashboard"
                render={props => {
                  return getAuthenticatedUser() ? (
                    <DashboardPage {...props} {...auth} />
                  ) : (
                    <Redirect to="/login" />
                  );
                }}
              />
              <Route
                path="/"
                render={props => {
                  return getAuthenticatedUser() ? (
                    <Redirect to="/dashboard" />
                  ) : (
                    <Redirect to="/login" />
                  );
                }}
              />
            </Switch>
          </Router>
        );
      }}
    </AuthenticatedUser>
  );
};

export default compose(withApollo)(App);
