import React from "react";
import { Form, Field } from "react-final-form";
import FieldError from "domain/form/FieldError";
import LabelledField from "domain/form/LabelledField";
import Buttons from "domain/form/Buttons";
import Button from "domain/form/Button";
import Fieldset from "domain/form/Fieldset";
import {
  required,
  email,
  minLength,
  maxLength,
  exactValue,
  composeValidators
} from "domain/form/validators";
import { makeRequest } from "domain/auth";

const onSubmit = (setAuthenticatedUser, history) => async ({
  email,
  password,
  firstName,
  lastName
}) => {
  const responseData = await makeRequest({
    endpoint: "register",
    data: { email, password, firstName, lastName }
  });

  if (responseData && responseData.validationErrors) {
    return responseData.validationErrors;
  }

  if (!(responseData && responseData.success)) {
    window.alert("Registration failed, please try again");
    return;
  }

  window.alert(
    "Registration almost complete!  Please now just check your email and click the link."
  );
};

const RegistrationForm = ({ history, setAuthenticatedUser }) => (
  <Form
    onSubmit={onSubmit(setAuthenticatedUser, history)}
    render={({ handleSubmit, form, submitting, pristine, values }) => (
      <form onSubmit={handleSubmit}>
        <Fieldset>
          <LabelledField
            label="First name"
            fieldId="firstName"
            field={
              <Field
                name="firstName"
                validate={composeValidators(
                  required,
                  minLength(2),
                  maxLength(50)
                )}
              >
                {({ input, meta }) => (
                  <React.Fragment>
                    <input
                      {...input}
                      type="text"
                      placeholder="First name"
                      id="firstName"
                    />
                    <FieldError meta={meta} />
                  </React.Fragment>
                )}
              </Field>
            }
          />
          <LabelledField
            label="Last name"
            fieldId="lastName"
            field={
              <Field
                name="lastName"
                validate={composeValidators(
                  required,
                  minLength(2),
                  maxLength(50)
                )}
              >
                {({ input, meta }) => (
                  <React.Fragment>
                    <input
                      {...input}
                      type="text"
                      placeholder="Last name"
                      id="lastName"
                    />
                    <FieldError meta={meta} />
                  </React.Fragment>
                )}
              </Field>
            }
          />
          <LabelledField
            label="Email"
            fieldId="email"
            field={
              <Field name="email" validate={composeValidators(required, email)}>
                {({ input, meta, ...rest }) => (
                  <React.Fragment>
                    <input
                      {...input}
                      type="text"
                      placeholder="Email"
                      id="email"
                    />
                    <FieldError meta={meta} />
                  </React.Fragment>
                )}
              </Field>
            }
          />
          <LabelledField
            label="Password"
            fieldId="password"
            field={
              <Field
                name="password"
                validate={composeValidators(
                  required,
                  minLength(9),
                  maxLength(20)
                )}
              >
                {({ input, meta }) => (
                  <React.Fragment>
                    <input
                      {...input}
                      type="password"
                      placeholder="Password"
                      id="password"
                    />
                    <FieldError meta={meta} />
                  </React.Fragment>
                )}
              </Field>
            }
          />
          <LabelledField
            label="Confirm password"
            fieldId="confirmpassword"
            field={
              <Field
                name="confirmpassword"
                validate={exactValue(values.password, "Passwords do not match")}
              >
                {({ input, meta }) => (
                  <React.Fragment>
                    <input
                      {...input}
                      type="password"
                      placeholder="Confirm password"
                      id="confirmpassword"
                    />
                    <FieldError meta={meta} />
                  </React.Fragment>
                )}
              </Field>
            }
          />
          <Buttons>
            <Button type="submit" disabled={submitting || pristine}>
              Submit
            </Button>
            <Button
              type="button"
              disabled={submitting || pristine}
              onClick={form.reset}
            >
              Reset
            </Button>
          </Buttons>
        </Fieldset>
      </form>
    )}
  />
);

export default RegistrationForm;
