import React from "react";
import AuthPage from "domain/pages/AuthPage";
import LoginForm from "./LoginForm";
import P from "domain/elements/text/P";
import { Link } from "react-router-dom";

const LoginPage = ({ history, setAuthenticatedUser }) => (
  <AuthPage>
    <LoginForm history={history} setAuthenticatedUser={setAuthenticatedUser} />
    <P>
      <Link to="/register">Register</Link>
    </P>
    <P>
      <Link to="/forgotten-password">Forgotten password</Link>
    </P>
  </AuthPage>
);

export default LoginPage;
